import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, Form } from '../login/components/styled';
import { useBoundingClient } from '../../common/utils/hooks';
import { Body, Headline } from '../../shared/styled-components/styled_text';
import { IconInput } from '../../shared/components/IconInput';
import { RegEx } from '../../common/utils/RegEx';
import { FilledButton, TextButton } from '../../shared/styled-components/styled_buttons';
import { AgreementsCheck, SignUpAgreements } from '../login/components/SignUpAgreements';
import { FlexCol } from '../../shared/FlexCol';
import axios from 'axios';
import { getApiUrl, postCheckSignUpCode, postSignUp } from '../../common/api/apiClient';
import { FlexRow } from '../../shared/FlexRow';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../AppRoutes';
import { AppContext } from '../../App';
import { setSavedAuth } from '../../common/utils/storage';
import { useTrack, useVariationDetail } from '@hackler/react-sdk';
import styled from 'styled-components/macro';
import { LoginRandomImage } from '../../common/components/LoginRandomImage';

export const SignUp: React.FC = () => {
  const [email, setEmail] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);
  const [verificationSendError, setVerificationSendError] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [name, setName] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [agreements, setAgreements] = useState<AgreementsCheck>();

  const [hasParams, setHasParams] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const { client: formClient } = useBoundingClient(formRef);
  const navigate = useNavigate();

  const { invite, redirect } = useContext(AppContext);
  const track = useTrack();
  const leadTextVariation = useVariationDetail(9).get('signup-readtext', '이름과 비밀번호를 입력해주세요.');

  useEffect(() => {
    track({ key: 'view_signup' });
  }, []);

  // Get params when coming from email registration on home page
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const t = params.get('token');
    const e = params.get('email');

    if (t) setVerificationCode(t);
    if (e) setEmail(e);
    if (t && e) setHasParams(true);
  }, []);

  // If params from email, check verification code
  useEffect(() => {
    if (hasParams && verificationCode && email) {
      setVerificationSent(true);
      handleTestVerificationCode();
    }
  }, [verificationCode, email, hasParams]);

  // If email changes, clear errors and flags
  // (Can't use useEffect because of params from email link)
  const handleEmailChange = (val: string) => {
    setHasParams(false);
    setVerificationSendError(false);
    setVerificationCodeError(false);
    setVerificationSent(false);
    setVerificationCode('');
    setEmail(val);
  };

  const handleSendVerification = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();

    try {
      setVerificationSendError(false);
      await axios.post(`${getApiUrl()}sign-up/verifications/email`, { email, is_from_promotion: 0 });
      setVerificationSent(true);
      track({ key: 'click_verification' });
    } catch (e) {
      console.log(e);
      setVerificationSent(false);
      setVerificationSendError(true);
    }
  };

  const handleTestVerificationCode = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();

    try {
      await postCheckSignUpCode(verificationCode, null, email);
      setVerificationCodeError(false);
      setEmailVerified(true);
    } catch (e) {
      console.error(e);
      setVerificationCodeError(true);
      setHasParams(false);
    }
  };

  const handleSignUp = (e: React.FormEvent) => {
    e.preventDefault();
    if (!verificationCode || !password1 || !name) return;
    track({ key: 'click_signup' });
    postSignUp(verificationCode, password1, name, agreements!.terms, agreements!.privacy, !!agreements?.service, email)
      .then(auth => {
        setSavedAuth({ ...auth, isLogin: true });
        if (invite) {
          navigate(appRoutes.inviteAccept.path());
        } else {
          navigate(appRoutes.createWorkspace.path());
        }
      })
      .catch(message => {
        if (message) {
          alert(message);
        }
      });
  };

  return (
    <Wrapper>
      <Container form={formClient} offset1={{ top: '6rem', left: '33rem' }}>
        <Form ref={formRef} onSubmit={handleSignUp}>
          <Headline size={'m'}>회원가입</Headline>
          <Body size={'m'}>{leadTextVariation}</Body>
          <FlexCol align={'flex-start'} width={'100%'} gap={'1.2rem'}>
            <FlexCol align={'flex-start'} width={'100%'} gap={'0.4rem'}>
              <IconInput
                value={email}
                onChange={handleEmailChange}
                style={{ width: '100%' }}
                required={true}
                placeholder={'이메일을 입력해주세요.'}
                pattern={RegEx.Email}
                patternMessage={'올바른 이메일 주소를 입력해주세요.'}
                type={'email'}
                errorMessage={verificationSendError ? '이미 사용중인 이메일이에요.' : undefined}
                autocomplete={false}
                icons={
                  emailVerified
                    ? {
                        end2: {
                          src: '/images/login/verified.svg',
                          width: '6.9rem',
                        },
                      }
                    : undefined
                }
                disabled={emailVerified}
              />
              {!emailVerified && (
                <FilledButton
                  size={'s'}
                  color={'primary'}
                  disabled={!email.trim().length || !RegEx.Email.test(email)}
                  style={{ width: '100%' }}
                  onClick={handleSendVerification}
                  type={'button'}
                >
                  인증코드 보내기
                </FilledButton>
              )}
            </FlexCol>

            {verificationSent && !emailVerified && (
              <FlexCol align={'flex-start'} width={'100%'} gap={'0.4rem'}>
                <IconInput
                  label={'인증코드'}
                  value={verificationCode}
                  onChange={setVerificationCode}
                  placeholder={'인증코드를 입력해주세요'}
                  required={true}
                  errorMessage={verificationCodeError ? '인증코드가 일치하지 않아요.' : undefined}
                  autocomplete={false}
                />
                <FilledButton
                  size={'s'}
                  color={'primary'}
                  disabled={!verificationCode.trim().length}
                  style={{ width: '100%' }}
                  onClick={handleTestVerificationCode}
                  type={'button'}
                >
                  인증하기
                </FilledButton>
              </FlexCol>
            )}

            <IconInput
              label={'이름'}
              value={name}
              onChange={setName}
              placeholder={'이름을 입력해주세요'}
              style={{ width: '100%' }}
              required={true}
              autocomplete={false}
            />

            <IconInput
              label={'비밀번호'}
              value={password1}
              onChange={setPassword1}
              placeholder={'영문+숫자 8자리 이상 입력해주세요'}
              style={{ width: '100%' }}
              required={true}
              pattern={RegEx.Password}
              patternMessage={'영문+숫자 8자리 이상 입력해주세요'}
              type={'password'}
              autocomplete={false}
            />
            <IconInput
              label={'비밀번호 확인'}
              value={password2}
              onChange={setPassword2}
              placeholder={'영문+숫자 8자리 이상 입력해주세요'}
              style={{ width: '100%' }}
              required={true}
              pattern={val => val === password1}
              patternMessage={'비밀번호가 일치하지 않습니다'}
              type={'password'}
              autocomplete={false}
            />
          </FlexCol>

          <SignUpAgreements onToggle={setAgreements} />
          <FilledButton
            size={'s'}
            color={'primary'}
            style={{ width: '100%' }}
            disabled={!verificationSent || verificationCodeError}
            type={'submit'}
          >
            회원가입
          </FilledButton>
        </Form>
        <FlexRow gap={'0.6rem'}>
          <Body size={'s'} style={{ color: 'var(--mono-60)' }}>
            이미 계정이 있으신가요?
          </Body>
          <TextButton size={'s'} color={'mono'} onClick={() => navigate(appRoutes.login.path())}>
            로그인
          </TextButton>
        </FlexRow>
      </Container>
      <LoginRandomImage />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  > div {
    flex: 1;
    min-width: 0;
  }
`;
