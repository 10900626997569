import { getSavedAuth, setSavedAuth } from '../utils/storage';
import axios, { AxiosError } from 'axios';
import { AuthResponse, Invite } from '../../shared/types/responseTypes';
import { User } from '../../shared/types/userTypes';
import { doRedirect, getFallbackRedirect, isDevEnv } from '../utils/utils';

export const getApiUrl = () => {
  // return 'http://192.168.0.132/api/v1/';
  return isDevEnv() ? 'https://test-api.candidate.im/api/v1/' : 'https://api.candidate.im/api/v1/';
  // return 'https://api.candidate.im/api/v1/';
};

export const postSignIn = async (account: string, password: string): Promise<AuthResponse> => {
  try {
    const apiUrl = getApiUrl();
    const { data } = await axios.post(`${apiUrl}sign-in/account`, { account, password });
    const token = data.data.token;
    return Promise.resolve(token);
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.status);
  }
};

export const postSignUp = async (
  token: string,
  password: string,
  name: string,
  is_check_term: boolean,
  is_collect_privacy: boolean,
  is_receive_service: boolean,
  email?: string,
  phone?: string,
): Promise<AuthResponse> => {
  try {
    const { data } = await axios.post(`${getApiUrl()}sign-up/${email ? 'email' : 'phone'}`, {
      token,
      password,
      name,
      email,
      phone: phone?.replaceAll('-', ''),
      is_check_term,
      is_collect_privacy,
      is_receive_service,
    });
    return Promise.resolve(data.data.token);
  } catch (e) {
    console.error(e);
    return Promise.reject((e as any).response.data.message);
  }
};

export const postSignUpBySocial = async (
  redirect: string,
  provider: 'naver' | 'kakao' | 'google' | 'apple',
  access_token: string,
  name: string,
  is_check_term: boolean,
  is_collect_privacy: boolean,
  is_receive_service: boolean,
): Promise<AuthResponse> => {
  const apiUrl = getApiUrl();
  const { data } = await axios.post(`${apiUrl}sign-up/socials`, {
    provider,
    access_token,
    name,
    is_check_term,
    is_collect_privacy,
    is_receive_service,
  });
  setSavedAuth({ ...data.data.token, isLogin: true });

  return data.data.token;
};

export const postSignInBySocial = async (
  redirect: string,
  provider: 'naver' | 'kakao' | 'google' | 'apple',
  access_token: string,
): Promise<void | { name: string; email: string }> => {
  try {
    const apiUrl = getApiUrl();
    const res = await axios.post(`${apiUrl}sign-in/socials`, {
      provider,
      access_token,
    });
    if (res.status === 201) {
      setSavedAuth({ ...res.data.data.token, isLogin: true });
      return Promise.resolve();
    }
    if (res.status === 200) {
      return res.data.data.social;
    }
  } catch {
    return Promise.reject();
  }
};

export const postRequestPasswordReset = async (type: 'email' | 'phone', target: string) => {
  try {
    await axios.post(`${getApiUrl()}forgot-password`, { type, target });
    return await Promise.resolve();
  } catch (e) {
    console.error(e);
    return await Promise.reject();
  }
};

export const patchResetPassword = async (type: 'email' | 'phone', target: string, token: string, password: string): Promise<void> => {
  try {
    const { data } = await axios.patch(`${getApiUrl()}reset-password`, { type, target, token, password });
    setSavedAuth({ ...data.data.token, isLogin: true });
    return await Promise.resolve();
  } catch (e) {
    console.error(e);
    return await Promise.reject((e as any).response.data.message);
  }
};

export const getUser = async (token: string): Promise<User> => {
  try {
    const { data } = await axios.get(`${getApiUrl()}me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.data;
  } catch (e) {
    console.error(e);
    return Promise.reject();
  }
};

export const deleteLeaveService = async (token: string) => {
  await axios.delete(`${getApiUrl()}sign-out`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postCheckSignUpCode = async (token: string, phone?: string | null, email?: string | null) => {
  try {
    return await axios.post(`${getApiUrl()}sign-up/verifications/${phone ? 'phone' : 'email'}/check`, {
      token,
      email,
      phone,
    });
  } catch {
    return Promise.reject();
  }
};

export const deleteUserProfileImage = async (token: string) => {
  await axios.delete(`${getApiUrl()}me/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postUpdateUserProfileImage = async (token: string, uuid: string) => {
  await axios.post(`${getApiUrl()}me/profile`, { uuid }, { headers: { Authorization: `Bearer ${token}` } });
};

export const postSendEmailVerification = async (token: string, email: string) => {
  try {
    await axios.post(`${getApiUrl()}me/email/verification`, { email }, { headers: { Authorization: `Bearer ${token}` } });
    return Promise.resolve();
  } catch (e) {
    return Promise.reject();
  }
};

export const postSendPhoneVerification = async (token: string, phone: string) => {
  try {
    await axios.post(`${getApiUrl()}me/phone/verification`, { phone }, { headers: { Authorization: `Bearer ${token}` } });
    return Promise.resolve();
  } catch (e) {
    return Promise.reject();
  }
};

export const patchUpdateUser = async (token: string, name: string) => {
  await axios.patch(`${getApiUrl()}me`, { name }, { headers: { Authorization: `Bearer ${token}` } });
  return name;
};

export const patchUpdateUserEmail = async (accessToken: string, email: string, token: string): Promise<User> => {
  try {
    const { data } = await axios.patch(
      `${getApiUrl()}me/email`,
      {
        email,
        token,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );
    return data.data;
  } catch (e) {
    console.error(e);
    const error = e as AxiosError;
    if (error.response?.status === 401) {
      return Promise.reject(error.response.status);
    }
  }
  return Promise.reject();
};

export const patchUpdateUserPhone = async (accessToken: string, phone: string, token: string): Promise<User> => {
  try {
    const { data } = await axios.patch(
      `${getApiUrl()}me/phone`,
      {
        phone,
        token,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );
    return Promise.resolve(data.data);
  } catch (e) {
    // console.error(e);
    const error = e as AxiosError;
    if (error.response?.status === 401) {
      return Promise.reject(error.response.status);
    }
  }
  return Promise.reject();
};

export const patchChangePassword = async (token: string, new_password: string) => {
  try {
    await axios.patch(`${getApiUrl()}me/password`, { password: new_password }, { headers: { Authorization: `Bearer ${token}` } });
    return await Promise.resolve();
  } catch (e) {
    console.error((e as any).response.data.message);
    return await Promise.reject((e as any).response.data.message);
  }
};

export const getInviteDetails = async (token: string): Promise<Invite> => {
  try {
    const { data } = await axios.get(`${getApiUrl()}invitation-link/${token}`);
    return data.data;
  } catch {
    return Promise.reject();
  }
};

export const postCreateCompany = (redirect?: string) => {
  const auth = getSavedAuth();
  axios
    .post(
      `${getApiUrl()}companies`,
      { name: '회사' },
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      },
    )
    .then(() => {
      setSavedAuth(auth!);
      doRedirect(redirect ?? getFallbackRedirect(), auth!);
    })
    .catch(console.error);
};

export const postAcceptInvite = async (token: string) => {
  const auth = getSavedAuth();
  const { data } = await axios.post(`${getApiUrl()}invitation-link/${token}`, null, {
    headers: {
      Authorization: `Bearer ${auth?.access_token}`,
    },
  });
};
